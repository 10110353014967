<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{ $t("add_dish") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form class="fix-for-nepali-label">
                    <v-text-field v-model="editedData.DishName"
                                  dense
                        outlined>
                        <template v-slot:label>
                            {{ $t("dish_name") }}
                        </template>
                    </v-text-field>
                    <v-text-field v-model="editedData.DishDescription"
                                  dense
                        outlined>
                        <template v-slot:label>
                            {{ $t("dish_description") }}
                        </template>
                    </v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">
                {{
        $t("cancel")
                }}
            </v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "CreateSpecialDishForm",
  props: ["success"],
  computed: {
    DishNameErrors() {
      const errors = [];
      if (!this.$v.editedData.DishName.$dirty) return errors;
          !this.$v.editedData.DishName.required &&
        errors.push(`${this.$t("dish_name")} ${this.$t("is_required")}`);
      return errors;
    },
    DishDescriptionErrors() {
      const errors = [];
      if (!this.$v.editedData.DishDescription.$dirty) return errors;
        !this.$v.editedData.DishDescription.required &&
        errors.push(`${this.$t("dish_description")} ${this.$t("is_required")}`);
      return errors;
    },
  },
  data() {
    return {
      returnData: [],
      show: false,
      updated: false,
      editedData: {
        DishName: "",
        DishDescription: "",
      },
      servicesData: {
        data: {},
        message: "",
        color: "",
      },
    };
  },
  validations: {
    editedData: {
      DishName: { required },
      DishDescription: { required },
    },
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      this.updated = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let param = {
          DishName: this.editedData.DishName,
          DishDescription: this.editedData.DishDescription,
        };
        axios
          .post("Hotel/InsertIntoHomestaySpecialDishAsync", param)
          .then((response) => {
            if (response.data.success) {
              this.editedData = {};
              this.servicesData.data = response.data;
              this.servicesData.message = "Dish added Succcessfully";
              this.servicesData.color = "success";
              this.dialogueClose();
            }
          })
          .catch((response) => {
            this.servicesData.data = response.data;
            this.servicesData.message =
              "Error Adding Dish, Please Contact Admin";
            this.servicesData.color = "error";
            this.dialogueClose();
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.editedData.Fare = "";
    },
  },
};
</script>

<style scoped></style>
